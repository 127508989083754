@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

body {
  font-family: "Space Grotesk", sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input {
  outline: none;
}
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }
}
@layer utilities {
  .flex-between {
    @apply flex justify-between;
  }
  .container {
    @apply mx-auto max-w-[1310px] w-full;
  }
  .sec-pad-x {
    @apply lg:px-[48px] md:px-[34px] px-[20px];
  }

  .sec-pad-y {
    @apply lg:py-[48px] md:py-[34px] py-[20px];
  }

  .f-col {
    @apply flex flex-col;
  }
  .nav-link {
    @apply text-white  md:text-base  text-sm md:font-semibold font-medium  hover:text-dark-primary transition-all cursor-pointer capitalize;
  }
  .nav-icon {
    @apply md:w-[25px] w-[20px] md:h-[25px] h-[20px] fill-current bg-dark-primary cursor-pointer rounded-full text-sm flex-center text-white;
  }
  .btn-border {
    @apply rounded-[96px] bg-light-primary shadow-primary p-[3px] bg-custom-gradiant;
  }
  .btn-inner {
    @apply rounded-[96px] py-[08px] px-[24px] bg-light-primary;
  }
  .btn{
    @apply rounded-[96px] py-[08px] px-[24px] capitalize;
  }
  .btn-inner-0 {
    @apply rounded-[96px] bg-light-primary;
  }

  .tab {
    @apply text-white inline-flex items-center rounded-tl-[96px] rounded-bl-[96px] bg-tint-purple h-full cursor-pointer  text-xs font-bold md:py-[8px] py-[6px] md:px-[16px] px-[12px] outline-none focus:ring-0 border-none;
  }
  .tab-no-radius {
    @apply text-white inline-flex items-center bg-tint-purple h-full md:py-[8px] py-[6px] md:px-[16px] px-[12px]
    text-xs font-bold cursor-pointer outline-none focus:ring-0 border-none;
  }
  .tab-r {
    @apply text-white inline-flex items-center rounded-tr-[96px] rounded-br-[96px] bg-tint-purple h-full text-xs font-bold md:py-[8px] py-[6px] md:px-[16px] px-[12px] cursor-pointer  outline-none focus:ring-0 border-none;
  }
  .card-input {
    @apply w-full p-0.5 border-none md:text-base text-sm font-bold text-black;
  }
}
.react-tabs__tab {
  outline: none;
  border: none;
}
.react-tabs__tab--selected > .tab,
.react-tabs__tab--selected > .tab-no-radius,
.react-tabs__tab--selected > .tab-r {
  @apply bg-secondary;
}

.react-tabs__tab-panel--selected {
  width: 100%;
}
.blob-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23e58f17%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23e86c2e%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M762 618Q636 736 502 732T191 614Q14 500 162 335.5T529 121q219-50 289 164.5T762 618Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M762 618Q636 736 502 732T191 614Q14 500 162 335.5T529 121q219-50 289 164.5T762 618Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.nav-link.active {
  @apply text-dark-primary;
}
